import h from 'hyperscript';
import focusTrap from 'focus-trap';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default class HeaderPanelToggleButton {
  constructor($toggleButton) {
    this.$toggleButton = $toggleButton;

    this.$panel = document.getElementById(this.$toggleButton.getAttribute('aria-controls'));
    this.$buffer = this.$panel.querySelector('.header__panel-head-buffer');
    this.state = 'not-sticky';
    this.offset = 16;
    this.$toggleButtonParent = this.$toggleButton.parentNode;
    this.$closeButton = this.$panel.querySelector('.header__panel-close');

    // Binded events
    this.bindedScroll = this.scroll.bind(this);
    this.bindedClick = this.click.bind(this);
    this.onKeydownBinded = this.onKeydown.bind(this);

    // Add events
    document.addEventListener('scroll', this.bindedScroll, { passive: true });
    this.$toggleButton.addEventListener('click', this.bindedClick);

    // Init focus trap
    this.focusTrap = focusTrap(this.$panel.parentNode, {
      escapeDeactivates: false,
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: false,
    });

    this.scroll();
  }

  deconstructor() {
    // Remove events
    document.removeEventListener('scroll', this.bindedScroll);
    this.$toggleButton.removeEventListener('click', this.bindedClick);

    // Kill focus trap
    if (this.focusTrap) {
      this.focusTrap.deactivate();
      this.focusTrap = null;
    }

    // Reset panel
    this.closePanel();
  }

  scroll() {
    const toggleButtonParentRect = this.$toggleButtonParent.getBoundingClientRect();

    if (toggleButtonParentRect.top <= 0 + this.offset && this.state === 'not-sticky') {
      this.stickToggleButton();
    } else if (toggleButtonParentRect.top > 0 + this.offset && this.state === 'sticky') {
      this.detachToggleButton();
    }
  }

  stickToggleButton() {
    this.state = 'sticky';
    window.requestAnimationFrame(() => {
      this.$toggleButton.classList.add('header__panel-toggle--sticky');
      this.$toggleButton.style.top = `${this.offset}px`;
    });
  }

  detachToggleButton() {
    this.state = 'not-sticky';
    window.requestAnimationFrame(() => {
      this.$toggleButton.classList.remove('header__panel-toggle--sticky');
      this.$toggleButton.style.top = '';
    });
  }

  onKeydown(event) {
    // Close menu on ESC
    if (event.keyCode === 27) {
      event.preventDefault();
      this.closePanel();
    }
  }

  closePanel() {
    if (document.body.querySelector('.header__panel-background')) {
      document.body.removeChild(this.$background);
    }

    document.body.removeEventListener('keydown', this.onKeydownBinded);

    // Change toggle button
    this.$toggleButton.classList.remove('header__panel-toggle--open');
    this.$toggleButton.setAttribute('aria-expanded', 'false');

    // Delete closing area
    const $closeArea = this.$panel.querySelector('.header__panel-close-area');
    if ($closeArea) {
      this.$panel.removeChild($closeArea);
    }

    // Start closing animation
    this.$panel.classList.remove('header__panel-inner--open');

    // Disable focus trap
    if (this.focusTrap) {
      this.focusTrap.deactivate();
    }

    // Re-enable scrolling
    enableBodyScroll(this.$panel);
  }

  openPanel() {
    // Change toggle button
    this.$toggleButton.classList.add('header__panel-toggle--open');
    this.$toggleButton.setAttribute('aria-expanded', 'true');

    // Change to close button
    this.$toggleButton.classList.add('header__panel-toggle--close');

    document.body.addEventListener('keydown', this.onKeydownBinded);

    // Add background-overlay
    this.$background = h('.header__panel-background');
    this.$background.addEventListener('click', () => {
      window.requestAnimationFrame(() => {
        this.closePanel();
      });
    });
    // Add background to body
    document.body.appendChild(this.$background);

    // Disable scrolling
    disableBodyScroll(this.$panel);

    // Show panel
    this.$panel.classList.add('header__panel-inner--open');

    // Create close area
    const $closeArea = h('button.header__panel-close-area');
    $closeArea.addEventListener('click', () => {
      window.requestAnimationFrame(() => {
        this.$panel.removeChild($closeArea);
        this.closePanel();
      });
    });

    // Add close area
    this.$panel.insertBefore($closeArea, this.$panel.querySelector('.header__panel-search'));

    // Enable focus trap
    this.focusTrap.activate();
  }

  click() {
    // Get current state of panel
    const isClosed = this.$toggleButton.getAttribute('aria-expanded') !== 'true';

    // Switch state
    window.requestAnimationFrame(() => {
      if (isClosed) {
        this.openPanel();
      } else {
        this.closePanel();
      }
    });
  }
}
