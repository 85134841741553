import getTarget from 'javascripts/utils/get-target';

// Switch state
function switchStateForDropdown(isOpen, $dropdownList, $dropdownControl) {
  $dropdownControl.setAttribute('aria-expanded', isOpen ? 'false' : 'true');
  $dropdownList.setAttribute('tabindex', isOpen ? '-1' : '');
  $dropdownList.querySelectorAll('.inline-dropdown__link').forEach(($link) => {
    $link.setAttribute('tabindex', isOpen ? '-1' : '0');
  });
}

document.addEventListener('click', (event) => {
  const $dropdownControl = getTarget(event.target, '.js-inline-dropdown');

  if ($dropdownControl) {
    const isOpen = $dropdownControl.getAttribute('aria-expanded') === 'true';
    const $dropdown = $dropdownControl.closest('.inline-dropdown');
    const $dropdownList = document.getElementById($dropdownControl.getAttribute('aria-controls'));

    // Switch state
    switchStateForDropdown(isOpen, $dropdownList, $dropdownControl);

    // Close dropdown on focusout
    const closeDropdownOnFocusout = (focusEvent) => {
      const $target = focusEvent.relatedTarget;

      if (($dropdown === $target || $dropdown.contains($target)) === false) {
        switchStateForDropdown(true, $dropdownList, $dropdownControl);
        $dropdown.removeEventListener('focusout', closeDropdownOnFocusout);
      }
    };

    // Catch clicks before focusout
    const catchClicksBeforeFocusout = () => {
      $dropdown.removeEventListener('focusout', closeDropdownOnFocusout);
      $dropdownControl.removeEventListener('mousedown', catchClicksBeforeFocusout);
    };

    // Focus list
    if (!isOpen) {
      $dropdownControl.addEventListener('mousedown', catchClicksBeforeFocusout);
      $dropdown.addEventListener('focusout', closeDropdownOnFocusout);
    }
  }
});
