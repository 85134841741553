import { MEDIA_QUERIES } from 'javascripts/constants';
import Masonry from 'masonry-layout';

const $newsTiles = document.querySelector('.js-news-tiles');

const masonry = () => {
  const mqm = window.matchMedia(MEDIA_QUERIES.m).matches;

  if (mqm) {
    const masonryNewslist = new Masonry($newsTiles, {
      itemSelector: '.news-tiles__item',
      columnWidth: '.news-tiles__item--default',
    });

    $newsTiles.addEventListener('lazyloaded', () => {
      masonryNewslist.layout();
    });
  }
};

if ($newsTiles) {
  // Init Masonry
  masonry();

  // Init Masonry again on resize
  window.addEventListener('resize', masonry);
}
