import getSiblings from 'javascripts/utils/get-siblings';

const factList = ($factList) => {
  const $facts = $factList.querySelectorAll('.fact-list__item');
  const $images = Array.prototype.slice.call($factList.querySelectorAll('.fact-list__image-item'));

  if ($facts && $images) {
    for (let i = 0; i < $facts.length; i += 1) {
      // Get references
      const $fact = $facts[i];
      const $factLink = $fact.querySelector('.fact-list__text');
      const $factImage = $images.find(images => images.dataset.fact === $fact.id);
      const $otherFacts = getSiblings($fact);
      const $otherImages = getSiblings($factImage);
      const activeClass = 'fact-list__item--active';
      const visibleClass = 'fact-list__image-item--visible';

      // Update states
      const updateStates = () => {
        // Set active state
        if (!$factImage.classList.contains(visibleClass)) {
          $factImage.classList.add(visibleClass);
        }

        // Check state
        if (!$fact.classList.contains(activeClass)) {
          $fact.classList.add(activeClass);

          // Handle sibling state
          $otherFacts.forEach(($otherFact) => {
            if ($otherFact.classList.contains(activeClass)) {
              $otherFact.classList.remove(activeClass);
              $otherFact.querySelector('.fact-list__text').blur();
            }
          });

          // Handle sibling images
          $otherImages.forEach(($otherImage) => {
            if ($otherImage.classList.contains(visibleClass)) {
              $otherImage.classList.remove(visibleClass);
            }
          });
        }
      };

      // Event listener
      $fact.addEventListener('mouseenter', updateStates, false);
      $factLink.addEventListener('focus', updateStates, false);
    }
  }
};

// Initialize
document.querySelectorAll('.js-fact-list').forEach($factList => factList($factList));
