import h from 'hyperscript';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import focusTrap from 'focus-trap';

const searchItems = [
  {
    title: 'Studieren im Ausland',
    url: '#1',
  },
  {
    title: 'Im Ausland lebenden Studenten an der Martin-Luther-Universität Halle-Wittenberg',
    url: '#2',
  },
  {
    title: 'Informationen rund ums Studium',
    url: '#3',
  },
  {
    title: 'Studierendenrat (StuRa). Hier gibt es Informationen von Studierenden für Studierende',
    url: '#4',
  },
  {
    title: 'Das Sprachenzentrum der Martin-Luther-Universität Halle-Wittenberg bietet allen Studierenden Studentenmobilität',
    url: '#5',
  },
  {
    title: 'Hier finden Sie die Kontaktdaten der universitären Prüfungsämter und Studienabteilungen',
    url: '#6',
  },
  {
    title: 'Studienorganisatorischen oder prüfungsrelevanten Fragen im  Studium',
    url: '#7',
  },
  {
    title: 'Langzeitstudiengebühren / Zweitstudiengebühren / Kurzzeitstudentenbeziehung',
    url: '#8',
  },
  {
    title: 'Werkstudenten Halle Langzeitstudiengebühren',
    url: '#9',
  },
  {
    title: 'Studienangebot von A bis Z',
    url: '#10',
  },
  {
    title: 'Martin-Luther-Universität Halle-Wittenberg',
    url: '#11',
  },
  {
    title: 'Studenten studieren in Halle. Studium in Halle-Wittenberg',
    url: '#11',
  },
];

export default class HeaderBarSearch {
  constructor($searchContainer) {
    // Get all elements
    this.$container = $searchContainer;
    this.$input = this.$container.querySelector('input');
    this.$parent = this.$container.parentNode;

    // Bar or panel
    if (this.$container.classList.contains('header__bar-search')) {
      this.openClass = 'header__bar-search--open';
      this.suggestionsClass = 'header__bar-search--suggestions';
    } else {
      this.openClass = 'header__panel-search--open';
      this.suggestionsClass = 'header__panel-search--suggestions';
    }

    // Binded events
    this.openBinded = this.openSearch.bind(this);
    this.closeBinded = this.closeAll.bind(this);
    this.focusBinded = this.focus.bind(this);
    this.focusoutBinded = this.focusout.bind(this);
    this.keyupBinded = this.keyup.bind(this);
    this.onKeydownBinded = this.onKeydown.bind(this);
    this.onOutsideClickBinded = this.onOutsideClick.bind(this);

    // States
    this.searchOpen = false;
    this.suggestionsOpen = false;

    this.init();
  }

  deconstructor() {
    this.deinit();
  }

  init() {
    // Add click event
    this.$container.addEventListener('click', this.openBinded);

    // Add focus events
    this.$input.addEventListener('focus', this.focusBinded);
    this.$container.addEventListener('focusin', this.focusBinded);

    // Add keyup events
    this.$input.addEventListener('keyup', this.keyupBinded);
  }

  deinit() {
    this.closeAll();

    // Remove events
    this.$container.removeEventListener('click', this.openBinded);
    this.$input.removeEventListener('focus', this.focusBinded);
    this.$container.removeEventListener('focusin', this.focusBinded);
    this.$container.removeEventListener('focusout', this.focusBinded);
    this.$input.removeEventListener('keyup', this.keyupBinded);
    this.$input.removeEventListener('keydown', this.keydownBinded);
    this.$container.removeEventListener('reset', this.closeBinded);
  }

  onKeydown(event) {
    // Close search on ESC
    if (event.keyCode === 27) {
      event.preventDefault();
      this.closeAll();
      event.stopPropagation();
    }
  }

  onOutsideClick(event) {
    const $target = event.target;

    if (this.$container.parentNode.contains($target)) {
      return;
    }

    this.closeAll();
  }

  closeAll() {
    if (this.suggestionsOpen) this.closeSearchSuggestions();
    if (this.searchOpen) this.closeSearch();
  }

  filterSearchItems(query) {
    const queryArr = query.split(' ');

    return searchItems.filter(
      obj => Object.keys(obj).some(
        (key) => {
          // Search for any of the query-terms
          for (let i = 0; i < queryArr.length; i += 1) {
            if (queryArr[i].length > 2) {
              const match = new RegExp(queryArr[i], 'i').test(obj[key]);
              if (match) {
                return true;
              }
            }
          }
          return false;
        },
      ),
    );
  }

  keyup() {
    this.searchQuery = this.$input.value;

    if (this.searchQuery.length > 2) {
      // Dummy function! Search results need to be obtained using an API later.
      const matches = this.filterSearchItems(this.searchQuery);

      if (matches.length) {
        this.showSearchSuggestions(matches);
      } else if (this.suggestionsOpen) {
        this.closeSearchSuggestions();
      }
    } else if (this.suggestionsOpen) {
      this.closeSearchSuggestions();
    }
  }

  showSearchSuggestions(results) {
    if (!this.suggestionsOpen) {
      // Background overlay
      this.$overlayBackground = document.body.appendChild(h('.header__search-suggestions-background'));

      // Search suggestions container
      this.$suggestionsContainerInner = h('.header__search-suggestions-inner');
      this.$suggestionsContainer = h('.header__search-suggestions', this.$suggestionsContainerInner);
      this.$container.parentNode.appendChild(this.$suggestionsContainer);
      this.$container.classList.add(this.suggestionsClass);

      // Init focus trap
      this.focusTrap = focusTrap(this.$container.parentNode, {
        escapeDeactivates: false,
        clickOutsideDeactivates: true,
        returnFocusOnDeactivate: false,
      });

      // Hide bar list from focus trap
      if (!this.$parent.classList.contains('header__panel-inner--sticky')) {
        this.$container.parentNode.querySelector('.header__bar-list').style.display = 'none';
      }

      // Keep keyboard focus inside search
      this.focusTrap.activate();

      document.documentElement.classList.add('has-scroll-lock');
      disableBodyScroll(this.$suggestionsContainer);

      this.suggestionsOpen = true;
    }

    // Show the suggestions
    this.$suggestionsContainerInner.innerHTML = '';

    const queryArr = this.searchQuery.split(' ');

    let $moreResults = null;
    let $moreResultsHellip = null;
    if (results.length > 6) {
      // eslint-disable-next-line no-param-reassign
      results.length = 6;
      $moreResultsHellip = h('a.header__search-suggestions-link', { href: '#' }, '…');
      $moreResults = h('a.button.button--secondary.header__search-suggestions-link-more', { href: '#' }, h('span.button__text', 'Alle Resultate'));
    }

    results.forEach((result) => {
      let highlightedText = result.title;

      for (let i = 0; i < queryArr.length; i += 1) {
        if (queryArr[i].length > 2) {
          const regex = new RegExp(`(${queryArr[i]})(?![^<]*>|[^<>]*</)`, 'ig');
          if (highlightedText.match(regex)) {
            highlightedText = highlightedText.replace(regex, '<mark class="mark">$1</mark>');
          }
        }
      }

      const $resultLink = document.createElement('a');
      $resultLink.classList.add('header__search-suggestions-link');
      $resultLink.setAttribute('href', result.url);
      $resultLink.innerHTML = highlightedText;

      this.$suggestionsContainerInner.appendChild($resultLink);
    });

    if ($moreResults) {
      this.$suggestionsContainerInner.appendChild($moreResultsHellip);
      this.$suggestionsContainerInner.appendChild($moreResults);
    }
  }

  closeSearchSuggestions() {
    // Enable body scroll
    document.documentElement.classList.remove('has-scroll-lock');
    enableBodyScroll(this.$suggestionsContainer);

    this.$overlayBackground.removeEventListener('click', this.closeBinded);

    // Show bar list
    if (!this.$parent.classList.contains('header__panel-inner--sticky')) {
      this.$container.parentNode.querySelector('.header__bar-list').removeAttribute('style');
    }

    // Remove focus trap
    this.focusTrap.deactivate();
    this.focusTrap = null;

    this.$container.classList.remove(this.suggestionsClass);

    // Remove the overlay background
    document.body.removeChild(this.$overlayBackground);

    this.$container.parentNode.removeChild(this.$suggestionsContainer);
    this.$suggestionsContainer = undefined;
    this.suggestionsOpen = false;
  }

  focus() {
    // Open search
    this.openSearch();
  }

  focusout(event) {
    if (!this.$container.contains(event.relatedTarget)) {
      this.closeSearch();
    }
  }

  openSearch() {
    // Open search container, remove click handler
    this.$container.classList.add(this.openClass);
    this.$container.removeEventListener('click', this.openBinded);

    // Add outside click event
    document.body.addEventListener('click', this.onOutsideClickBinded);

    // Add keydown for esc-key to close search
    document.body.addEventListener('keydown', this.onKeydownBinded);

    // Add close on form reset
    this.$container.addEventListener('reset', this.closeBinded);

    // Remove focus events
    this.$input.removeEventListener('focus', this.focusBinded);
    this.$container.removeEventListener('focusin', this.focusBinded);

    // Focus on input
    this.$input.setAttribute('placeholder', 'Suchbegriff eingeben');
    this.$input.focus();

    this.searchOpen = true;
  }

  closeSearch() {
    if (this.suggestionsOpen) this.closeSearchSuggestions();

    // Change placeholder back, delete value
    this.$input.setAttribute('placeholder', 'Suchen');
    this.$input.value = '';
    this.$input.blur();

    // Remove keydown for esc-key to close search
    document.body.removeEventListener('keydown', this.onKeydownBinded);

    // Remove event on reset
    this.$container.removeEventListener('reset', this.closeBinded);

    // Add focus events back
    this.$input.addEventListener('focus', this.focusBinded);
    this.$container.addEventListener('focusin', this.focusBinded);

    // Close search container
    this.$container.classList.remove(this.openClass);
    this.$container.addEventListener('click', this.openBinded);

    // Add outside click event
    document.body.removeEventListener('click', this.onOutsideClickBinded);

    this.searchOpen = false;
  }
}
