export default class NavigationDesktop {
  constructor($navigation) {
    this.$navigation = $navigation;
  }

  init() {
    // Set inline style=column-count: [data-cols];
    this.$navigation.querySelectorAll('.navigation__submenu--level-2').forEach(($navLevel2) => {
      const $el = $navLevel2;
      const cols = $el.getAttribute('data-cols');
      if (cols > 0) {
        $el.style.columnCount = cols;
      }
    });
  }

  deinit() {
    // Remove all inline styles
    this.$navigation.querySelectorAll('.navigation__submenu--level-2').forEach(($navLevel2) => {
      $navLevel2.removeAttribute('style');
    });
  }
}
