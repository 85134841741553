import { MEDIA_QUERIES } from 'javascripts/constants';
import gumshoe from 'gumshoejs';
import throttle from 'javascripts/utils/throttle';

export default class Subnavigation {
  constructor($tableOfContents) {
    this.$toc = $tableOfContents;
    this.$tocList = this.$toc.querySelector('.sub-navigation__list');
    this.$tocBackLink = this.$toc.querySelector('.sub-navigation__back-link');

    // Max Height
    this.tocMaxHeight = 635;

    // Sticky state
    this.change = 380;
    this.changeBottom = 680;
    this.state = 'not-sticky';
    this.stateBottom = 'not-sticky';

    // Bind events
    this.stickyBinded = throttle(this.sticky.bind(this));
  }

  init() {
    // Register events
    document.addEventListener('scroll', this.stickyBinded, { passive: true });

    // Init scroll spy
    gumshoe.init({
      selector: '.sub-navigation__item',
      offset: 140,
      activeClass: 'sub-navigation__item--active',
    });

    // Make Sticky (if needed)
    this.sticky();

    // Adjust height (if needed)
    this.setHeight();
  }

  deinit() {
    // Deregister events
    document.removeEventListener('scroll', this.stickyBinded);

    // Remove classes
    this.$toc.classList.remove('sub-navigation--sticky-bottom');
    this.$toc.classList.remove('sub-navigation--sticky');

    // Stop scroll spy
    gumshoe.destroy();
  }

  setHeight() {
    let listHeight = this.$tocList.offsetHeight;
    let tocHeight = this.$tocList.offsetHeight;

    if (this.$tocBackLink && !(tocHeight > this.tocMaxHeight)) {
      listHeight += this.$tocBackLink.offsetHeight;
      tocHeight += this.$tocBackLink.offsetHeight;
    }

    if (listHeight > this.tocMaxHeight) {
      listHeight = this.tocMaxHeight - this.$tocBackLink.offsetHeight;
    } else {
      this.$toc.style.height = `${tocHeight}px`;
    }

    this.$tocList.style.height = `${listHeight}px`;
  }

  sticky() {
    const currentScroll = window.scrollY || window.pageYOffset;
    const { offsetHeight } = this.$toc;
    const switchBottom = document.body.clientHeight - 120 - offsetHeight - this.changeBottom;

    if (currentScroll >= this.change && this.state === 'not-sticky') {
      this.state = 'sticky';
      window.requestAnimationFrame(() => this.$toc.classList.add('sub-navigation--sticky'));
    } else if (currentScroll < this.change && this.state === 'sticky') {
      this.state = 'not-sticky';
      window.requestAnimationFrame(() => this.$toc.classList.remove('sub-navigation--sticky'));
    }

    if (currentScroll >= switchBottom && this.stateBottom === 'not-sticky') {
      this.stateBottom = 'sticky';
      window.requestAnimationFrame(() => this.$toc.classList.add('sub-navigation--sticky-bottom'));
    } else if (currentScroll < switchBottom && this.stateBottom === 'sticky') {
      this.stateBottom = 'not-sticky';
      window.requestAnimationFrame(() => this.$toc.classList.remove('sub-navigation--sticky-bottom'));
    }
  }
}

// Init Subnavigation
document.querySelectorAll('.js-sub-navigation').forEach(($subnav) => {
  const mql = window.matchMedia(MEDIA_QUERIES.l);
  const subnavigation = new Subnavigation($subnav);

  // Listener
  const onMediaQueryChange = (mq) => {
    const isDesktop = mq.matches;

    if (isDesktop) {
      subnavigation.init();
    } else {
      subnavigation.deinit();
    }
  };

  // Listen on media query changes
  mql.addListener(onMediaQueryChange);

  // First run
  onMediaQueryChange(mql);
});
