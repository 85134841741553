import h from 'hyperscript';
import CustomEvent from 'custom-event';

export default function showAlert({
  headline, text, moreLink, closeButton, callback = null, timeout = 0,
}) {
  // More link
  let $moreLink = null;
  if (moreLink && moreLink.link) {
    $moreLink = h(
      'a.link',
      {
        href: moreLink.link,
      },
      moreLink.text,
    );
  }

  // Confirm button
  let $closeButton = null;
  if (closeButton) {
    $closeButton = h(
      'button.button.button--primary.alert__button',
      {
        type: 'button',
      },
      h('span.button__text', closeButton.text),
    );
  }

  // Generate alert template
  const $alert = h(
    '.notice.notice--cookie.t-gray-dark',
    h(
      '.notice__inner',
      h(
        '.notice__content',
        h(
          '.notice__headline',
          h(
            'h2.headline.headline--6', headline,
          ),
        ),
        h('.notice__text.text', text, $moreLink),
      ),
      h('.notice__button', $closeButton),
    ),
  );

  // Button click handler
  $closeButton.addEventListener('click', (event) => {
    $alert.remove();

    if (callback) {
      callback.call(null, event);
    }

    const cookieAcceptEvent = new CustomEvent('cookieAccepted', {
      bubbles: true,
    });
    document.dispatchEvent(cookieAcceptEvent);
  });

  const $firstBodyChild = document.body.firstChild;

  // Attach to body
  document.body.insertBefore($alert, $firstBodyChild);

  if (timeout > 0) {
    let timeoutVar;

    // Remove alert after 'timeoutTime' seconds if timeout parameter is set
    const removeAlertTimeout = (timeoutTime) => {
      timeoutVar = setTimeout(() => {
        $alert.remove();
      }, timeoutTime);
    };

    removeAlertTimeout(timeout);

    // Clear timeout on ouseenter
    $alert.addEventListener('mouseenter', () => {
      clearTimeout(timeoutVar);
    });

    // Reinit removeAlertTimeout after mouseleave
    $alert.addEventListener('mouseleave', () => {
      removeAlertTimeout(2000);
    });
  }
}
