class Form {
  constructor($el) {
    this.$el = $el;
    this.$content = this.$el.querySelector('.list-filter__groups-wrapper[aria-hidden="true"]');
    this.$toggle = this.$el.querySelector('.list-filter__toggle');

    this.init();
  }

  init() {
    this.initExpand();
  }

  initExpand() {
    if (this.$toggle) {
      this.hideFields();

      this.$toggle.addEventListener('click', (event) => {
        event.preventDefault();

        if (this.$toggle.classList.contains('link--show')) {
          this.$toggle.classList.remove('link--show');
          this.$toggle.classList.add('link--hide');
          this.$toggle.setAttribute('aria-expanded', 'true');
          this.$toggle.innerText = this.$toggle.dataset.hide;
          this.showFields();
        } else {
          this.$toggle.classList.add('link--show');
          this.$toggle.classList.remove('link--hide');
          this.$toggle.setAttribute('aria-expanded', 'false');
          this.$toggle.innerText = this.$toggle.dataset.show;
          this.hideFields();
        }
      });
    }
  }

  hideFields() {
    this.$content.setAttribute('aria-hidden', 'true');
  }

  showFields() {
    this.$content.setAttribute('aria-hidden', 'false');
  }
}

document.querySelectorAll('.js-list-filter').forEach(
  $el => new Form($el),
);
