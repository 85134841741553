import { lory } from '@rsm/allfarblori';
import iconButton from 'components/atoms/icon-button/icon-button';
import { t } from '../../../javascripts/utils/withTranslation';

export default class Slider {
  constructor($el) {
    this.$el = $el;
    this.$slidesFrame = this.$el.querySelector('.slider__slides-frame');
    this.$slidesContainer = this.$el.querySelector('.slider__slides');
    this.$slides = this.$el.querySelectorAll('.slider__slide');

    // Don't init slider, if only one slide exists
    if (this.$slides.length === 1) {
      return;
    }

    this.$el.addEventListener('before.lory.init', () => {
      this.$slidesContainer.scrollLeft = 0;
      this.imageSizer();
      this.initControls();
    });

    this.$el.addEventListener('after.lory.init', () => {
      this.$el.classList.add('slider--initialized');
    });

    this.$el.addEventListener('on.lory.resize', () => {
      this.imageSizer();
      this.positionControls();
    });

    this.lory = lory(this.$el, {
      rewind: true,
      rewindPrev: true,
      rewindSpeed: 200,
      slideSpeed: 350,
      ease: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
      classNameFrame: 'slider__slides-frame',
      classNameSlideContainer: 'slider__slides',
      classNamePrevCtrl: 'slider__control--prev',
      classNameNextCtrl: 'slider__control--next',
      classNameDisabledPrevCtrl: 'slider__control--disabled',
      classNameDisabledNextCtrl: 'slider__control--disabled',
    });
  }

  getMediaHeight() {
    // Get defined height of Slider Images
    // (as represented by height of first contained .figure__media)
    return this.$el.querySelector('.figure__media').getBoundingClientRect().height;
  }

  imageSizer() {
    const sliderHeight = this.getMediaHeight();

    // Make sure slide images are contained within sliderWidth and sliderHeight
    // and are resized proportionally
    this.$slides.forEach((e) => {
      const $slideImage = e.querySelector('.image__img');
      $slideImage.removeAttribute('style');

      const $slideImageRect = $slideImage.getBoundingClientRect();

      if ($slideImageRect.height > sliderHeight) {
        $slideImage.style.height = `${sliderHeight}px`;
      }
    });
  }

  initControls() {
    this.$controlPrev = iconButton({
      icon: 'angle-left',
      title: t('Vorheriger Slide'),
      medium: true,
      classes: ['slider__control', 'slider__control--prev'],
    });

    this.$controlNext = iconButton({
      icon: 'angle-right',
      title: t('Nächster Slide'),
      medium: true,
      classes: ['slider__control', 'slider__control--next'],
    });

    this.$slidesFrame.appendChild(this.$controlPrev);
    this.$slidesFrame.appendChild(this.$controlNext);

    this.positionControls();
  }

  positionControls() {
    this.$controlPrev.style.height = `${this.getMediaHeight()}px`;
    this.$controlNext.style.height = `${this.getMediaHeight()}px`;
  }
}

document.querySelectorAll('.slider').forEach($slider => new Slider($slider));
