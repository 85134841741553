import Scrollable from 'javascripts/utils/scrollable';

document.querySelectorAll('.js-logo-slider').forEach(($logoSlider) => {
  // Init scrollable
  const scrollableInstance = new Scrollable(
    $logoSlider,
    $logoSlider.querySelector('.logo-slider__list'),
    'logo-slider__button',
  );

  // Update slider on image loads
  $logoSlider.addEventListener('lazyloaded', () => scrollableInstance.scroll());
});
