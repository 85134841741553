import { MEDIA_QUERIES } from 'javascripts/constants';
import HeaderPanelToggleButton from './header-panel-toggle-button';
import HeaderBarSearch from './header-bar-search';
import HeaderSticky from './header-sticky';

let componentStore = [];

function onMediaQueryChange(mq) {
  const isDesktop = mq.matches;

  // Disable all inited components
  componentStore.forEach(component => component.deconstructor && component.deconstructor());
  componentStore = [];

  // Init desktop
  if (isDesktop) {
    // Sticky header
    document
      .querySelectorAll('.header__panel-inner')
      .forEach($panel => componentStore.push(new HeaderSticky($panel)));

    // Header bar & panel search
    document
      .querySelectorAll('.header__bar-search, .header__panel-search')
      .forEach($searchContainer => componentStore.push(new HeaderBarSearch($searchContainer)));

  // Init mobile
  } else {
    // Panel toggle button
    document
      .querySelectorAll('.header__panel-toggle')
      .forEach($toggleButton => componentStore.push(new HeaderPanelToggleButton($toggleButton)));

    // Header bar search
    document
      .querySelectorAll('.header__bar-search')
      .forEach($searchContainer => componentStore.push(new HeaderBarSearch($searchContainer)));
  }
}

// Init media query
const mql = window.matchMedia(MEDIA_QUERIES.l);

// Run updates
mql.addListener(onMediaQueryChange);

// Init
onMediaQueryChange(mql);
