import h from 'hyperscript';
import getVideoId from 'get-video-id';
import plyr from 'plyr';
import randomId from 'javascripts/utils/random-id';
import generateImage from 'components/atoms/image/image';

export default function figure({
  tag = 'figure', caption, image,
}) {
  const figureId = `figure-${randomId()}`;
  return h(
    `${tag}.figure`,
    {
      attrs: {
        id: figureId,
        'aria-labelledby': caption && `${figureId}-caption`,
      },
    },
    h('.figure__media', generateImage(image)),
    caption && h('figcaption.figure__caption', {
      id: `${figureId}-caption`,
    }, caption),
  );
}

// Audio
plyr.setup(document.querySelectorAll('.figure__audio'), {
  iconPrefix: 'icon',
  i18n: {
    play: 'Abspielen',
    pause: 'Pausieren',
    seek: 'Abspielzeit',
    currentTime: 'Aktuelle Abspielzeit',
    mute: 'Stummschalten',
    unmute: 'Stummschaltung aufheben',
  },
  controls: [
    'play',
    'progress',
    'current-time',
    'mute',
  ],
});

// Internal videos
plyr.setup(document.querySelectorAll('.figure__video'), {
  loadSprite: false,
  i18n: {
    restart: 'Neustarten',
    rewind: '{seektime} Sekunden zurückspulen',
    play: 'Abspielen',
    pause: 'Pausieren',
    forward: '{seektime} Sekunden vorspulen',
    buffered: 'vorgeladen',
    currentTime: 'Aktuelle Abspielzeit',
    duration: 'Länge',
    volume: 'Lautstärke',
    mute: 'Stummschalten',
    unmute: 'Stummschaltung aufheben',
    toggleMute: 'Ton an-/abschalten',
    toggleCaptions: 'Untertitel an-/abschalten',
    toggleFullscreen: 'Vollbild an-/abschalten',
  },
  iconPrefix: 'icon',
  controls: [
    'play-large',
    'play',
    'progress',
    'current-time',
    'mute',
    'volume',
    'captions',
    'fullscreen',
  ],
});

class Figure {
  constructor($el) {
    // Elements
    this.$el = $el;
    this.$media = this.$el.querySelector('.figure__media');
    this.$videoPlay = this.$el.querySelector('.figure__play');

    // Add events
    if (this.$videoPlay) {
      this.$videoPlay.addEventListener('click', this.onVideoClick.bind(this));
    }
  }

  onVideoClick(event) {
    event.preventDefault();

    const { id, service } = getVideoId(this.$videoPlay.href);

    if (id && service === 'youtube') {
      // Construct Youtube iframe player, add rel=0 to disable related videos
      const $iframe = h(
        '.figure__video-wrapper',
        h('iframe.figure__video', {
          src: `https://www.youtube.com/embed/${id}?rel=0&autoplay=1`,
          allowFullscreen: true,
          msAllowFullscreen: true,
          webkitAllowFullscreen: true,
          mozAllowFullscreen: true,
        }),
      );

      // Replace with iframe
      this.$videoPlay.parentNode.replaceChild($iframe, this.$videoPlay);
    }
  }

  getMediaType() {
    return this.$el.querySelector('.figure__play') ? 'video' : 'image';
  }
}

document.querySelectorAll('.js-figure').forEach($el => new Figure($el));

// export default figure;
