import { lory } from '@rsm/allfarblori';
import h from 'hyperscript';
import generateIcon from 'components/_particles/icon/icon';
import { t } from '../../../javascripts/utils/withTranslation';

export default class FrontpageSlider {
  constructor($el) {
    this.$el = $el;
    this.$slidesFrame = this.$el.querySelector('.frontpage-header__slides-frame');
    this.$slidesContainer = this.$el.querySelector('.frontpage-header__slides');
    this.$slides = this.$el.querySelectorAll('.frontpage-header__slide');
    this.$images = this.$el.querySelectorAll('.frontpage-header__image');

    // Don't init slider, if only one slide exists
    if (this.$slides.length === 1) {
      return;
    }

    this.$el.addEventListener('before.lory.init', () => {
      this.$slidesContainer.scrollLeft = 0;
      this.initControls();
      this.initProgressbar();
    });

    this.$el.addEventListener('after.lory.init', () => {
      this.$el.classList.add('frontpage-header--initialized');
      this.initAutoplay();
    });

    this.$el.addEventListener('after.lory.slide', () => {
      this.updateProgressbar();
    });

    this.$el.addEventListener('on.lory.resize', () => {
      this.positionControls();
      this.positionProgressbar();
    });

    this.lory = lory(this.$el, {
      rewind: true,
      rewindPrev: true,
      rewindSpeed: 200,
      slideSpeed: 350,
      ease: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
      classNameFrame: 'frontpage-header__slides-frame',
      classNameSlideContainer: 'frontpage-header__slides',
      classNamePrevCtrl: 'frontpage-header__control--prev',
      classNameNextCtrl: 'frontpage-header__control--next',
      classNameDisabledPrevCtrl: 'frontpage-header__control--disabled',
      classNameDisabledNextCtrl: 'frontpage-header__control--disabled',
    });

    this.$slidesContainer.addEventListener('focusin', (event) => {
      this.$slidesContainer.scrollLeft = 0;
      const $slide = event.target.closest('.frontpage-header__slide');
      const index = Array.prototype.indexOf.call(this.$slides, $slide);
      this.lory.slideTo(index);
    });
  }

  initControls() {
    this.$controlPrev = h(
      'button.frontpage-header__control.frontpage-header__control--prev',
      {
        type: 'button',
        tabIndex: '-1',
        title: t('Vorheriger Slide'),
      },
      generateIcon({
        icon: 'angle-left',
        viewBox: '0 0 70 200',
      }),
      h('span.u-hidden-visually', t('Vorheriger Slide')),
    );

    this.$controlNext = h(
      'button.frontpage-header__control.frontpage-header__control--next',
      {
        type: 'button',
        tabIndex: '-1',
        title: t('Nächster Slide'),
      },
      generateIcon({
        icon: 'angle-right',
        viewBox: '0 0 70 200',
      }),
      h('span.u-hidden-visually', t('Nächster Slide')),
    );

    this.$controlPlay = h(
      'button.frontpage-header__control.frontpage-header__control--play',
      {
        type: 'button',
        title: t('Automatisch abspielen pausieren'),
        attrs: {
          'aria-pressed': this.isAutoplay() ? 'true' : 'false',
        },
      },
      generateIcon({
        icon: 'play',
        viewBox: '0 0 70 200',
      }),
      generateIcon({
        icon: 'pause',
        viewBox: '0 0 70 200',
      }),
      h('span.u-hidden-visually', t('Automatisch abspielen pausieren')),
    );

    const topPos = this.$images[0].getBoundingClientRect().height + 8;

    this.$controls = h(
      '.frontpage-header__controls', {
        style: `position: absolute; top: ${topPos}px; left: 0;`,
      },
      this.$controlPrev,
      this.$controlPlay,
      this.$controlNext,
    );

    this.$slidesFrame.appendChild(this.$controls);
  }

  positionControls() {
    const topPos = this.$images[0].getBoundingClientRect().height + 8;
    this.$el.querySelector('.frontpage-header__controls').style.top = `${topPos}px`;
  }

  initProgressbar() {
    const initialProgress = Math.round(100 / this.$slides.length);
    const topPos = this.$images[0].getBoundingClientRect().height - 1;

    this.$progressBar = h(
      '.frontpage-header__progress',
      {
        id: this.progressBarId,
        style: `position: absolute; top: ${topPos}px; left: 0;`,
      },
      h(
        '.frontpage-header__progress-inner',
        {
          style: `width: ${initialProgress}%`,
        },
      ),
    );

    this.$slidesFrame.appendChild(this.$progressBar);
  }

  updateProgressbar() {
    const progress = (Math.round(100 / this.$slides.length) * (this.lory.returnIndex() + 1));
    this.$el.querySelector('.frontpage-header__progress-inner').style.width = `${progress}%`;
  }

  positionProgressbar() {
    const topPos = this.$images[0].getBoundingClientRect().height - 1;
    this.$el.querySelector('.frontpage-header__progress').style.top = `${topPos}px`;
  }

  isPlaying() {
    return (this.$el.getAttribute('data-playing') === 'true');
  }

  isAutoplay() {
    return (this.$el.getAttribute('data-autoplay') === 'true');
  }

  initAutoplay() {
    // Touch kill
    let hasTouched = false;

    // Init timer
    const timerInit = () => setInterval(() => this.lory.next(), 5000);
    let timer = this.isPlaying() ? timerInit() : false;

    // Kill timer if a mouse enters the slider
    this.$el.addEventListener('mouseover', () => {
      clearInterval(timer);
    });

    // Restart timer if a mouse leaves the slider
    this.$el.addEventListener('mouseleave', () => {
      if (!hasTouched && this.isPlaying()) timer = timerInit();
    });

    // Kill the timer if at least on touch interaction happend
    this.$el.addEventListener('touchstart', function killOnTouchStart() {
      clearInterval(timer);
      this.$el.revomeEventListener('touchstart', killOnTouchStart);
      hasTouched = true;
    }, { passive: true });

    // Autoplay toggle button (play/pause)
    this.$controlPlay.addEventListener('click', () => {
      if (this.isPlaying()) {
        this.$controlPlay.setAttribute('aria-pressed', 'false');
        this.$el.setAttribute('data-playing', 'false');
        clearInterval(timer);
      } else {
        this.$controlPlay.setAttribute('aria-pressed', 'true');
        this.$el.setAttribute('data-playing', 'true');
        if (!hasTouched) timer = timerInit();
        setTimeout(() => this.lory.next(), 500);
      }
    });
  }
}

document.querySelectorAll('.frontpage-header').forEach($frontpageSlider => new FrontpageSlider($frontpageSlider));
