import getTarget from 'javascripts/utils/get-target';

document.addEventListener('click', (event) => {
  const $target = getTarget(event.target, '.js-print');

  if ($target) {
    event.preventDefault();

    window.print();
  }
});
