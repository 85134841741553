// Get sibling elements
export default function getSiblings($elem) {
  const siblings = [];
  let $sibling = $elem.parentNode.firstChild;

  while ($sibling) {
    if ($sibling.nodeType === 1 && $sibling !== $elem) {
      siblings.push($sibling);
    }
    $sibling = $sibling.nextSibling;
  }

  return siblings;
}
