import h from 'hyperscript';

const image = ({
  tag = 'div', height, width, copyright, src, alt = '', notresponsive = false, cover = false,
}) => h(
  `${tag}.image`,
  {
    className: (notresponsive && 'image image--not-responsive') || 'image',
    style: (!notresponsive && !cover && width && height) && `padding-top: ${(height / width) * 100}%;`,
  },
  h('img.image__img', {
    src,
    width,
    height,
    alt,
  }),
  copyright && h(
    'span.image__copyright.image__copyright--right',
    h('span.copyright', h('span.copyright__text', copyright)),
  ),
);

export default image;
