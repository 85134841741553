import randomId from 'javascripts/utils/random-id';
import Popper from 'popper.js';
import h from 'hyperscript';
import { t } from 'javascripts/utils/withTranslation';
import icon from 'components/_particles/icon/icon';

export default class Tooltip {
  constructor({ $ref, text, id }) {
    this.$ref = $ref;
    this.popper = null;

    // Templates
    this.$text = h('p.tooltip__text', { attrs: { tabindex: '0' } }, text);
    this.$arrow = h('.tooltip__arrow', { attrs: { 'x-arrow': '' } });

    this.$close = h(
      'button.tooltip__close',
      {
        type: 'button',
        title: t('Begriffserklärung schließen'),
        hidden: true,
      },
      icon({
        icon: 'cross',
      }),
    );

    this.$tooltip = h(
      `.tooltip__popup#${id}`,
      {
        attrs: {
          'aria-hidden': 'true',
        },
      },
      this.$text,
      this.$arrow,
      this.$close,
    );

    this.$ref.parentNode.insertBefore(this.$tooltip, this.$ref.nextSibling);

    // Initialize popper
    this.popper = new Popper(
      this.$ref,
      this.$tooltip,
      {
        placement: 'right-end',
        modifiers: {
          flip: {
            behavior: [
              'right',
              'right-start',
              'bottom',
              'left',
              'left-start',
              'top',
            ],
          },
        },
      },
    );

    // Bind events
    this.onMouseenterBinded = this.onMouseenter.bind(this);
    this.onMouseleaveBinded = this.onMouseleave.bind(this);
    this.onClickBinded = this.onClick.bind(this);
    this.onOutsideClickBinded = this.onOutsideClick.bind(this);
    this.onTooltipFocusoutBinded = this.onTooltipFocusout.bind(this);
    this.onCloseClickBinded = this.onCloseClick.bind(this);

    this.$ref.addEventListener('mouseenter', this.onMouseenterBinded);
    this.$ref.addEventListener('click', this.onClickBinded);
    this.$close.addEventListener('click', this.onCloseClickBinded);
  }

  onMouseenter() {
    this.$ref.addEventListener('mouseleave', this.onMouseleaveBinded);

    this.show();
  }

  onMouseleave() {
    this.$ref.removeEventListener('mouseleave', this.onMouseleaveBinded);

    this.hide();
  }

  onClick() {
    this.$ref.removeEventListener('mouseleave', this.onMouseleaveBinded);
    this.$ref.removeEventListener('mouseenter', this.onMouseenterBinded);

    this.$close.hidden = false;

    this.show();

    this.$text.focus();

    document.addEventListener('click', this.onOutsideClickBinded);
    this.$tooltip.addEventListener('focusout', this.onTooltipFocusoutBinded);
  }

  onOutsideClick(event) {
    const $target = event.target;

    if (this.isInside($target)) {
      return;
    }

    this.hideAfterClick();
  }

  onTooltipFocusout(event) {
    const $target = event.relatedTarget;

    if (this.isInside($target)) {
      return;
    }

    this.hideAfterClick();
  }

  onCloseClick() {
    this.hideAfterClick();

    this.$ref.focus();
  }

  show() {
    this.$tooltip.setAttribute('aria-hidden', 'false');

    this.popper.update();
  }

  hide() {
    this.$tooltip.setAttribute('aria-hidden', 'true');
  }

  hideAfterClick() {
    this.hide();

    this.$close.hidden = true;

    document.removeEventListener('click', this.onOutsideClickBinded);
    this.$ref.addEventListener('mouseenter', this.onMouseenterBinded);
    this.$tooltip.removeEventListener('focusout', this.onTooltipFocusoutBinded);
  }

  isInside($target) {
    return (
      this.$tooltip.contains($target)
      || this.$tooltip === $target
      || this.$ref.contains($target)
      || this.$ref === $target
    );
  }
}

export const attachTooltip = ($ref) => {
  const text = $ref.getAttribute('title');
  const id = `tooltip-${randomId()}`;

  if (!text) {
    return null;
  }

  $ref.removeAttribute('title');
  $ref.setAttribute('aria-label', 'Begriffserklärung anzeigen');

  return new Tooltip({ id, $ref, text });
};

document
  .querySelectorAll('.js-tooltip')
  .forEach(attachTooltip);
