// Get item index
export default function getIndex($elem) {
  const $parent = $elem.parentNode;
  const $children = $parent.children;

  let i = $children.length - 1;

  for (; i >= 0; i -= 1) {
    if ($elem === $children[i]) {
      break;
    }
  }

  return i;
}
