import h from 'hyperscript';
import classNames from 'classnames';
import generateIcon from 'components/_particles/icon/icon';

export default function iconButton({
  tag, type, title, icon, link, medium = false, large = false, xlarge = false,
  floating = false, white = false, border = false, disabled = false, classes = false, tabindex = '0', ariaHidden = false,
}) {
  return h(`${(link && 'a') || tag || 'button'}`, {
    className: classNames(
      'icon-button',
      medium && 'icon-button--medium',
      large && 'icon-button--large',
      xlarge && 'icon-button--xlarge',
      border && 'icon-button--border',
      floating && 'icon-button--floating',
      white && 'icon-button--white',
      classes && classes,
    ),
    type: type || (!link && 'button'),
    href: link,
    title,
    disabled,
    attrs: {
      tabindex,
      'aria-hidden': ariaHidden,
    },
  }, h('.icon-button__circle', generateIcon({ icon, classes: ['icon-button__icon'] })));
}
