import store from 'store/dist/store.modern';
import { t } from 'javascripts/utils/withTranslation';
import showAlert from '../utils/alert';

if (!store.get('cookieconsent-mlu') && !document.body.classList.contains('no-cookie-warning')) {
  const cookieNoticeHeadline = t('Diese Seite verwendet Cookies.');
  const cookieNoticeText = t('Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. ');

  // Show alert
  showAlert({
    headline: cookieNoticeHeadline,
    text: cookieNoticeText,
    moreLink: { text: t('Mehr Informationen'), link: '#' },
    closeButton: { text: t('OK') },
    callback: () => store.set('cookieconsent-mlu', true),
  });
}
