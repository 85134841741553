import getTarget from 'javascripts/utils/get-target';

document.addEventListener('click', (event) => {
  const $target = getTarget(event.target, '.js-toggle-accordion');

  if ($target) {
    // Stop event here
    event.preventDefault();

    // Get state
    const $slide = $target.closest('.accordion__item');
    const $toggles = $slide.querySelectorAll('.js-toggle-accordion');
    const $content = document.getElementById($toggles[0].getAttribute('aria-controls'));
    const accordionContentVisible = $toggles[0].getAttribute('aria-expanded') === 'true';

    // Switch toggles
    $toggles.forEach($toggle => $toggle.setAttribute('aria-expanded', accordionContentVisible ? 'false' : 'true'));

    // Switch content visiblity
    if (accordionContentVisible) {
      $content.classList.remove('accordion__content--visible');
    } else {
      $content.classList.add('accordion__content--visible');
    }
  }
});
