import Pikaday from 'pikaday';
import moment from 'moment';
import { i18n } from 'javascripts/constants';
import { t } from 'javascripts/utils/withTranslation';

document
  .querySelectorAll('input[type=date]')
  .forEach(($input) => {
    if ($input.type !== 'date') {
      new Pikaday({
        field: $input,
        i18n: {
          previousMonth: t('Vorheriger Monat'),
          nextMonth: t('Nächster Monat'),
          months: [
            t('Januar'),
            t('Februar'),
            t('März'),
            t('April'),
            t('Mai'),
            t('Juni'),
            t('Juli'),
            t('August'),
            t('September'),
            t('Oktober'),
            t('November'),
            t('Dezember'),
          ],
          weekdays: [t('Sonntag'), t('Montag'), t('Dienstag'), t('Mittwoch'), t('Donnerstag'), t('Freitag'), t('Samstag')],
          weekdaysShort: [t('So'), t('Mo'), t('Di'), t('Mi'), t('Do'), t('Fr'), t('Sa')],

        },
        firstDay: 1,
        format: 'DD.MM.YYYY',
      });
    }
  });
