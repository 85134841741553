import getTarget from 'javascripts/utils/get-target';
import detectPrefixes from 'javascripts/utils/detect-prefixes';
import unvisibleFocus from 'javascripts/utils/unvisible-focus';

const prefixes = detectPrefixes();

export default class NavigationMobile {
  dontFocusBackToMoveLink = true;

  constructor($navigation) {
    this.$navigation = $navigation;
    this.$panelSearch = document.querySelector('.header__panel-search');

    // Events
    this.onMoveLinkClickBinded = this.onMoveLinkClick.bind(this);
    this.onBackLinkClickBinded = this.onBackLinkClick.bind(this);
    this.onFocusinBinded = this.onFocusin.bind(this);
  }

  init() {
    this.$navigation.addEventListener('click', this.onMoveLinkClickBinded);
    this.$navigation.addEventListener('click', this.onBackLinkClickBinded);
    this.$navigation.addEventListener('focusin', this.onFocusinBinded);

    // Add aria attributes
    this.$navigation
      .querySelectorAll('.navigation__item-link.navigation__move, .navigation__submenu-item-link.navigation__move')
      .forEach($el => $el.setAttribute('aria-expanded', 'false'));
  }

  deinit() {
    this.$navigation.removeEventListener('click', this.onMoveLinkClickBinded);
    this.$navigation.removeEventListener('click', this.onBackLinkClickBinded);
    this.$navigation.removeEventListener('focusin', this.onFocusinBinded);

    // Reset navigation height
    this.$navigation.style.height = '';
    // Reset navigation classnames
    this.$navigation.removeAttribute('class');
    this.$navigation.classList.add('navigation');

    // Find open submenus
    this.$navigation.querySelectorAll('.navigation__submenu--current').forEach(($submenu) => {
      $submenu.classList.remove('navigation__submenu--current');
    });

    // Remove aria attributes
    this.$navigation
      .querySelectorAll('.navigation__item-link.navigation__move, .navigation__submenu-item-link.navigation__move')
      .forEach($el => $el.removeAttribute('aria-expanded'));
  }

  adjustNavigationHeight() {
    let $reference = false;

    if (this.$navigation.classList.contains('navigation--level-6')) {
      $reference = this.$navigation.querySelector('.navigation__submenu--current .navigation__submenu--current .navigation__submenu--current .navigation__submenu--current .navigation__submenu--current');
    } else if (this.$navigation.classList.contains('navigation--level-5')) {
      $reference = this.$navigation.querySelector('.navigation__submenu--current .navigation__submenu--current .navigation__submenu--current .navigation__submenu--current');
    } else if (this.$navigation.classList.contains('navigation--level-4')) {
      $reference = this.$navigation.querySelector('.navigation__submenu--current .navigation__submenu--current .navigation__submenu--current');
    } else if (this.$navigation.classList.contains('navigation--level-3')) {
      $reference = this.$navigation.querySelector('.navigation__submenu--current .navigation__submenu--current');
    } else if (this.$navigation.classList.contains('navigation--level-2')) {
      $reference = this.$navigation.querySelector('.navigation__submenu--current');
    }

    if ($reference) {
      const dimensions = $reference.getBoundingClientRect();
      this.$navigation.style.height = `${dimensions.height + 132}px`;
      this.$panelSearch.style.display = 'none';
    } else {
      this.$navigation.removeAttribute('style');
      this.$panelSearch.style.display = 'block';
    }
  }

  adjustUtilityNavigationSubmenuPos() {
    const mainNavHeight = this.$navigation.querySelector('.navigation__items--main').getBoundingClientRect().height;

    this.$navigation
      .querySelectorAll('.navigation__items--utility .navigation__submenu')
      .forEach(($el) => {
        $el.style.top = `-${mainNavHeight}px`;
      });
  }

  onMoveLinkClick(event) {
    const $moveLink = getTarget(event.target, '.navigation__move');

    if ($moveLink) {
      event.preventDefault();

      const $submenu = $moveLink.parentNode.querySelector('.navigation__submenu');

      // Already active?
      if ($submenu.classList.contains('navigation__submenu--current')) {
        return;
      }

      // Make submenu visible
      $submenu.classList.add('navigation__submenu--current');

      // Move 1 level down
      const navigationClasses = this.$navigation.className;
      const whichLevel = parseInt(navigationClasses.substr(navigationClasses.length - 1), 10);
      this.$navigation.className = `navigation navigation--level-${whichLevel + 1}`;

      // Set aria attribute
      $moveLink.setAttribute('aria-expanded', 'true');

      // Set focus to the first element
      const setFocus = () => {
        const $firstElement = $submenu.querySelector('.navigation__submenu-item:not(.navigation__submenu-item--back)');
        const $link = $firstElement.querySelector('.navigation__submenu-item-link');
        unvisibleFocus($link);
        this.$navigation.removeEventListener(prefixes.transitionEnd, setFocus);
      };
      this.$navigation.addEventListener(prefixes.transitionEnd, setFocus);

      // Adjust height
      this.adjustNavigationHeight();

      this.adjustUtilityNavigationSubmenuPos();
    }
  }

  onBackLinkClick(event) {
    const $backLink = getTarget(event.target, '.navigation__back');

    if ($backLink) {
      event.preventDefault();

      // Get elements
      const $submenu = $backLink.closest('.navigation__submenu');
      const $item = $submenu.closest('.navigation__submenu-item, .navigation__item');

      // Move 1 level up
      const navigationClasses = this.$navigation.className;
      const whichLevel = parseInt(navigationClasses.substr(navigationClasses.length - 1), 10);
      this.$navigation.className = `navigation navigation--level-${whichLevel - 1}`;

      // Hide submenu
      // setTimeout(() => {
      //   $submenu.classList.remove('navigation__submenu--current');
      // }, 500);
      $submenu.classList.remove('navigation__submenu--current');

      // Remove aria attribute
      const $moveLink = $item.querySelector('.navigation__move');
      $moveLink.setAttribute('aria-expanded', 'false');

      // Set focus to move link back
      if (!this.dontFocusBackToMoveLink) {
        unvisibleFocus($moveLink);
      }

      // Adjust height
      this.adjustNavigationHeight();

      // Reset
      this.dontFocusBackToMoveLink = false;
    }
  }

  onFocusin(event) {
    const $level1 = getTarget(event.target, '.navigation__item-link');
    const $level2 = getTarget(event.target, '.navigation__submenu--level-2 > .navigation__submenu-item > .navigation__submenu-item-link');
    const $level3 = getTarget(event.target, '.navigation__submenu--level-3 > .navigation__submenu-item > .navigation__submenu-item-link');
    const $level4 = getTarget(event.target, '.navigation__submenu--level-4 > .navigation__submenu-item > .navigation__submenu-item-link');
    const $level5 = getTarget(event.target, '.navigation__submenu--level-5 > .navigation__submenu-item > .navigation__submenu-item-link');
    const $winner = $level1 || $level2 || $level3 || $level4 || $level5 || false;

    if ($winner) {
      if (
        ($level1 && this.$navigation.classList.contains('navigation--level-2'))
        || ($level2 && this.$navigation.classList.contains('navigation--level-3'))
        || ($level3 && this.$navigation.classList.contains('navigation--level-4'))
        || ($level4 && this.$navigation.classList.contains('navigation--level-5'))
        || ($level5 && this.$navigation.classList.contains('navigation--level-6'))
      ) {
        // Find open submenu
        const allOpenSubmenu = Array.from(this.$navigation.querySelectorAll('.navigation__submenu--current'));

        // Don't focus back to the back link
        this.dontFocusBackToMoveLink = true;

        // Close all
        if ($level1) {
          allOpenSubmenu.reverse().forEach(($submenu) => {
            const $backLink = $submenu.querySelector('.navigation__back');
            $backLink.click();
          });

        // Close last one
        } else {
          const $submenu = allOpenSubmenu[allOpenSubmenu.length - 1];
          const $backLink = $submenu.querySelector('.navigation__back');
          $backLink.click();
        }

        // Focus back
        unvisibleFocus($winner);
      }
    }
  }
}
