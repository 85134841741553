import getTarget from 'javascripts/utils/get-target';

document.addEventListener('mousedown', ({ target }) => {
  const $target = getTarget(target, '.input');

  if ($target) {
    const removeClassAfterBlur = () => {
      $target.classList.remove('input--has-focus-by-mousedown');
      $target.removeEventListener('blur', removeClassAfterBlur);
    };

    $target.classList.add('input--has-focus-by-mousedown');
    $target.addEventListener('blur', removeClassAfterBlur);
  }
});
