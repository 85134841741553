import A11yDialog from 'a11y-dialog';
import h from 'hyperscript';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import icon from 'components/_particles/icon/icon';
import randomId from 'javascripts/utils/random-id';
import { t } from 'javascripts/utils/withTranslation';

// Init overlay
export const initOverlay = ($overlay) => {
  // Init A11yDialog
  const overlay = document.body.appendChild($overlay);
  const dialog = new A11yDialog(overlay);

  // Add closers
  $overlay.querySelectorAll('.overlay__close, .overlay__background').forEach(($closer) => {
    $closer.addEventListener('click', () => dialog.destroy());
  });

  // Lock body on opening
  dialog.on('show', () => {
    // Disable scrolling
    document.documentElement.classList.add('has-scroll-lock');
    disableBodyScroll($overlay);
  });

  // Destory on hide
  dialog.on('hide', () => {
    // Reenable scrolling
    document.documentElement.classList.remove('has-scroll-lock');
    enableBodyScroll($overlay);

    // Remove overlay from DOM
    dialog.destroy();
  });

  dialog.on('destroy', () => {
    // Reenable scrolling
    document.documentElement.classList.remove('has-scroll-lock');
    enableBodyScroll($overlay);

    // Remove overlay from DOM
    document.body.removeChild($overlay);
  });

  return dialog;
};

// Create overlay on the fly
const createOverlay = (title, description = null, $content = null, open = false) => {
  // Check for support of native dialog
  const HTMLDialogElementSupported = (typeof HTMLDialogElement === 'function');
  let $background = null;

  // Generate base ID
  const baseId = `overlay-${randomId()}`;

  // Close button
  const $button = h('button.overlay__close', {
    type: 'button',
    attrs: {
      'data-a11y-dialog-hide': '',
      'aria-label': t('Dieses Overlay schließen'),
    },
  }, icon({ icon: 'cross' }));

  if (!HTMLDialogElementSupported) {
    // Background
    $background = h('.overlay__background', {
      tabIndex: '-1',
      attrs: {
        'data-a11y-dialog-hide': '',
      },
    });
  }

  // Overlay
  const $overlay = h(
    '.overlay', { id: baseId },
    $background,
    h('dialog.overlay__dialog', {
      open,
      attrs: {
        'aria-labelledby': `${baseId}-title`,
      },
    }, [
      h('.overlay__headline', h('h1.headline.headline--6', { id: `${baseId}-title` }, title)),
      description && h('.overlay__description', h('p', description)),
      $content && h('.overlay__content', $content),
      $button,
    ]),
  );

  // Init dialog
  const dialog = initOverlay($overlay, open);

  // Return dialog instance and DOM references
  return {
    dialog, $overlay, $button, $background, titleId: `${baseId}-title`,
  };
};

// Init existing overlays
document.querySelectorAll('.overlay').forEach($overlay => initOverlay($overlay));

export default createOverlay;
