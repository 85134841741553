import scroll from 'scroll';
import scrollDoc from 'scroll-doc';

const isSamePage = $link => ((
  $link.protocol !== window.location.protocol
  || $link.host !== window.location.host
  || $link.pathname !== window.location.pathname
  || $link.search !== window.location.search
) === false);

const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;

if (!isSmoothScrollSupported) {
  document.addEventListener('click', (event) => {
    if (event.target instanceof HTMLAnchorElement && isSamePage(event.target)) {
      const $target = document.querySelector(event.target.hash);

      if ($target) {
        event.preventDefault();

        window.requestAnimationFrame(() => scroll.top(
          scrollDoc(),
          $target.offsetTop,
          () => {
            window.history.replaceState({}, '', event.target.hash);
            $target.focus();
          },
        ));
      }
    }
  });
}
