import getTarget from 'javascripts/utils/get-target';

document.addEventListener('click', (event) => {
  const $teaserImage = getTarget(event.target, '.teaser__image');

  if ($teaserImage) {
    const $teaser = $teaserImage.closest('.teaser');
    const $link = $teaser.querySelector('.teaser__overlay-link');

    if ($link) {
      $link.click();
    }
  }
});
