import { MEDIA_QUERIES } from 'javascripts/constants';
import throttle from 'javascripts/utils/throttle';

// Scroll listener to update
const $images = document.querySelectorAll('.js-breaker-parallax');
const scrollListener = throttle(() => {
  window.requestAnimationFrame(() => {
    const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    $images.forEach(($image) => {
      const $breaker = $image.closest('.breaker');
      const breakerRect = $breaker.getBoundingClientRect();
      const imageRect = $image.getBoundingClientRect();

      // Apply effect only if element is visible in viewport
      if ((breakerRect.top + breakerRect.height) >= 0 && breakerRect.top <= height) {
        const scrolled = (height - breakerRect.top) / (height + breakerRect.height);
        const buffer = imageRect.height - breakerRect.height;
        const limit = Math.min(Math.max(scrolled * buffer, 0), 200);

        // eslint-disable-next-line no-param-reassign
        $image.style.transform = `translate3d(0,${limit}px, 0)`;
      }
    });
  });
});

// Init everything
const mediaQuery = window.matchMedia(MEDIA_QUERIES.m);
if (mediaQuery.matches && $images.length > 0) {
  window.addEventListener('scroll', scrollListener, { passive: true });
}

// Disable scroll listener on media query changes
mediaQuery.addListener((mq) => {
  if (mq.matches) {
    window.addEventListener('scroll', scrollListener, { passive: true });
  } else {
    window.removeEventListener('scroll', scrollListener);
  }
});
