import throttle from 'javascripts/utils/throttle';

export default class FooterFeedbackSticky {
  constructor($stickyElem) {
    this.$stickyElem = $stickyElem;
    this.$footerInner = this.$stickyElem.parentNode.querySelector('.footer__inner');

    this.state = 'not-sticky';

    // Bind events
    this.scrollBinded = throttle(this.scroll.bind(this));
    this.updateSwitchPointBinded = throttle(this.updateSwitchPoint.bind(this));

    // Update switch point on resize or orientationchange
    document.addEventListener('resize', this.updateSwitchPointBinded);
    document.addEventListener('orientationchange', this.updateSwitchPointBinded);

    // Add scroll event
    document.addEventListener('scroll', this.scrollBinded, { passive: true });

    // First run
    this.updateSwitchPoint();
    this.scroll();
  }

  deconstructor() {
    // Remove sticky class
    this.$stickyElem.classList.remove('footer__sticky-wrapper--sticky');

    // Deregister all events
    document.removeEventListener('resize', this.updateSwitchPointBinded);
    document.removeEventListener('orientationchange', this.updateSwitchPointBinded);
    document.removeEventListener('scroll', this.scrollBinded);
  }

  updateSwitchPoint() {
    // Define switch point
    this.switch = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  }

  scroll() {
    window.requestAnimationFrame(() => {
      const bounds = this.$footerInner.getBoundingClientRect();

      // Get scroll position
      const scroll = window.scrollY || window.pageYOffset;

      if (bounds.top >= this.switch && this.state === 'not-sticky') {
        this.state = 'sticky';
        this.$stickyElem.classList.add('footer__sticky-wrapper--sticky');
      } else if (bounds.top < this.switch && this.state === 'sticky') {
        this.state = 'not-sticky';
        this.$stickyElem.classList.remove('footer__sticky-wrapper--sticky');
      }

      if (scroll > 150) {
        this.$stickyElem.classList.add('footer__sticky-wrapper--scrolled');
      } else if (scroll < 150 && this.$stickyElem.classList.contains('footer__sticky-wrapper--scrolled')) {
        this.$stickyElem.classList.remove('footer__sticky-wrapper--scrolled');
      }
    });
  }
}
