import h from 'hyperscript';
import uriEncode from 'javascripts/utils/strict-uri-encode';
import getTarget from 'javascripts/utils/get-target';
import ClipboardJS from 'clipboard';
import generateIcon from 'components/_particles/icon/icon';
import createOverlay from 'components/atoms/overlay/overlay';
import { t } from 'javascripts/utils/withTranslation';

const MAIL_SUBJECT = t('Gefunden auf uni-halle.de');

const generateSharelink = ({
  title, icon, link = null, popup = false,
}) => {
  const $shareLink = h(
    `${link ? 'a' : 'button'}.share__link${(popup && '.js-popup') || ''}`,
    {
      type: !link && 'button',
      href: link,
      target: (link && !popup) && '_blank',
      rel: (link && !popup) && 'noopener noreferrer',
      'data-name': popup && title,
    },
    generateIcon({
      icon,
      classes: ['share__icon'],
    }),
    h('span.share__label', title),
  );

  return $shareLink;
};

const generateDialogContent = (url, title) => {
  // Copy link
  const $copy = h('li.share__item', generateSharelink({
    title: 'Link kopieren',
    icon: 'copy',
  }));

  // Build sharing list
  const $content = h('ul.share', {
    attrs: {
      tabindex: '0',
      autofocus: true,
    },
  }, [
    // Facebook
    h('li.share__item', generateSharelink({
      title: t('Facebook'),
      icon: 'facebook',
      popup: true,
      link: `https://www.facebook.com/sharer.php?u=${url}`,
    })),

    // Twitter
    h('li.share__item', generateSharelink({
      title: t('Twitter'),
      icon: 'twitter',
      popup: true,
      link: `https://twitter.com/intent/tweet?url=${uriEncode(url)}&text=${uriEncode(title)}&via=unihalle`,
    })),

    // XING
    h('li.share__item', generateSharelink({
      title: t('XING'),
      icon: 'xing',
      popup: true,
      link: `https://www.xing.com/spi/shares/new?url=${uriEncode(url)}`,
    })),

    // Mail
    h('li.share__item', generateSharelink({
      title: t('E-Mail'),
      icon: 'mail',
      link: `mailto:?subject=${uriEncode(MAIL_SUBJECT)}&body=${uriEncode(`${title} - ${url}`)}`,
    })),

    // WhatsApp
    h('li.share__item', generateSharelink({
      title: t('WhatsApp'),
      icon: 'whatsapp',
      link: `https://wa.me/?text=${uriEncode(`${title} - ${url}`)}`,
    })),

    // Copy link
    ClipboardJS.isSupported() && $copy,
  ]);

  // Generate overlay
  const { dialog, $overlay, titleId } = createOverlay(
    t('Teilen'),
    t('Optionaler Erklärtext ipsum dolor sit amet, consetetur sed diam nonumy eirmod tempor invidunt.'),
    $content,
  );

  // Set reference
  $content.setAttribute('aria-labelledby', titleId);

  // // Init clipboard.js
  const clipboard = new ClipboardJS($copy, {
    container: $overlay,
    text: () => url,
  });

  // // On success copy
  clipboard.on('success', () => {
    const $label = $copy.querySelector('.share__label');
    $label.innerText = t('Link wurde erfolgreich kopiert');
  });

  // Destory clipboard on hide
  dialog.on('hide', () => {
    clipboard.destroy();
  });

  return dialog;
};

document.addEventListener('click', (event) => {
  const $target = getTarget(event.target, '.js-share');

  if ($target) {
    event.preventDefault();

    const { url, title } = $target.dataset;
    const dialog = generateDialogContent(url, title);
    dialog.show();
  }
});
