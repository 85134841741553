import getTarget from 'javascripts/utils/get-target';

document.addEventListener('click', (event) => {
  const $toggle = getTarget(event.target, '.breadcrumb__toggle');

  if ($toggle) {
    $toggle.setAttribute('hidden', true);

    const $breadcrumb = $toggle.closest('.breadcrumb');
    const $list = $breadcrumb.querySelector('.breadcrumb__list');
    $list.classList.add('breadcrumb__list--visible');
    $list.setAttribute('tabindex', '0');
    $list.focus();
  }
});
