const environment = window.mlu || {};

export const ASSET_BASE_URL = environment.ASSET_BASE_URL || '/';

export const ICON_SPRITE_URL = environment.ICON_SPRITE_URL || '/icons/icons.svg';

export const TITLE = environment.TITLE || 'Martin-Luther-Universität Halle-Wittenberg';

export const LANG = document.documentElement.lang || 'de';

export const MEDIA_QUERIES = {
  s: '(min-width: 0px)',
  m: '(min-width: 768px)',
  l: '(min-width: 1025px)',
  xl: '(min-width: 1440px)',
};
