import h from 'hyperscript';
import generateIcon from 'components/_particles/icon/icon';
import createOverlay from 'components/atoms/overlay/overlay';
import { t } from 'javascripts/utils/withTranslation';

class ArticleTools {
  constructor($el) {
    this.$el = $el;
    this.$moreButton = this.$el.querySelector('.group__more');
    this.$options = this.$el.querySelectorAll('.icon-button:not(.group__more)');

    this.dialog = false;

    this.init();
  }

  init() {
    this.initEvents();
  }

  initEvents() {
    this.$moreButton.addEventListener('click', (event) => {
      event.preventDefault();

      this.generateDialogContent();
      this.dialog.show();
    });
  }

  generateDialogContent() {
    const $toolList = h('.share',
      [...this.$options].map(
        $option => h('.share__item', this.generateListLink({
          title: $option.getAttribute('title'),
          icon: $option.querySelector('use').getAttribute('xlink:href').replace('#icon-', ''),
          print: $option.classList.contains('js-print'),
          share: $option.classList.contains('js-share'),
          dataset: $option.dataset,

          ...($option.getAttribute('href') ? { link: $option.getAttribute('href') } : {}),
        })),
      ));

    // Generate overlay
    const { dialog, titleId } = createOverlay(
      t('Mehr'),
      t('Optionaler Erklärtext ipsum dolor sit amet, consetetur sed diam nonumy eirmod tempor invidunt.'),
      $toolList,
    );

    // Set reference
    $toolList.setAttribute('aria-labelledby', titleId);

    this.dialog = dialog;
  }

  generateListLink({
    title, icon, link = null, popup = false, print = false, share = false, dataset,
  }) {
    return h(
      `${link ? 'a' : 'button'}.share__link${(popup && '.js-popup') || ''}${(print && '.js-print') || ''}${(share && '.js-share') || ''}`, {
        type: !link && 'button',
        href: link,
        target: (link && !popup) && '_blank',
        rel: (link && !popup) && 'noopener noreferrer',
        'data-name': popup && title,
        'data-url': dataset.url,
        'data-title': dataset.title,
      },
      generateIcon({
        icon,
        classes: ['share__icon'],
      }),
      h('span.share__label', title),
    );
  }
}

document.querySelectorAll('.js-group').forEach(
  $el => new ArticleTools($el),
);
