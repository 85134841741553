import h from 'hyperscript';
import { lory } from '@rsm/allfarblori';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import focusTrap from 'focus-trap';
import { t } from 'javascripts/utils/withTranslation';
import randomId from 'javascripts/utils/random-id';
import getIndex from 'javascripts/utils/get-index';
import icon from 'components/_particles/icon/icon';
import iconButton from 'components/atoms/icon-button/icon-button';
import figure from 'components/molecules/figure/figure';

export default class Lightbox {
  constructor($lightboxTrigger) {
    this.$lightboxTrigger = $lightboxTrigger;

    this.$lightbox = null;
    this.isGallery = false;

    this.bindedClick = this.click.bind(this);
    this.bindedClose = this.close.bind(this);
    this.onKeydownBinded = this.onKeydown.bind(this);

    this.init();
  }

  deconstructor() {

  }

  init() {
    this.$lightboxTrigger.addEventListener('click', this.bindedClick);
    this.isGallery = this.$lightboxTrigger.dataset.gallery;
  }

  click(event) {
    event.preventDefault();
    this.generateLightbox();
  }

  close() {
    document.body.removeChild(this.$lightbox);

    this.focusTrap.deactivate();

    document.documentElement.classList.remove('has-scroll-lock');
    enableBodyScroll(this.$lightbox);

    this.$lightbox.removeEventListener('keydown', this.onKeydownBinded);

    this.$lightbox = null;
  }

  onKeydown(event) {
    // Close lightbox on ESC
    if (event.keyCode === 27) {
      event.preventDefault();
      this.close();
    }
  }

  generateLightboxItem(item) {
    const imgSrc = item.getAttribute('href');
    const imgAlt = item.querySelector('img').getAttribute('alt');

    let imgCaption = null;
    if (item.parentNode.querySelector('figcaption')) {
      imgCaption = item.parentNode.querySelector('figcaption').innerHTML;
    }

    let imgCopyright = null;
    if (item.querySelector('.copyright__text')) {
      imgCopyright = item.querySelector('.copyright__text').innerHTML;
    }

    const $figure = figure({
      caption: imgCaption,
      image: {
        src: imgSrc,
        alt: imgAlt,
        notresponsive: true,
        copyright: imgCopyright,
      },
    });

    const $item = h('.lightbox__item', h('.lightbox__loading'), h('.lightbox__figure', $figure));

    return $item;
  }

  generateLightbox() {
    // Generate base ID
    const baseId = `lightbox-${randomId()}`;

    // Close button
    const $closeButton = h('button.lightbox__close', {
      type: 'button',
      attrs: {
        'aria-label': t('Galerie schließen'),
      },
    }, icon({ icon: 'cross' }));

    $closeButton.addEventListener('click', this.bindedClose);

    // Background
    const $background = h('.lightbox__background', {
      tabIndex: '-1',
    });

    $background.addEventListener('click', this.bindedClose);

    // Gallery functionality
    let $galleryItems;
    let $items = [];

    if (this.isGallery) {
      $galleryItems = document.querySelectorAll(`*[data-gallery=${this.isGallery}]`);

      $galleryItems.forEach(($item) => {
        $items.push(this.generateLightboxItem($item));
      });

      $items = h('.lightbox__slider-frame', h('.lightbox__slider-slides', $items));
    } else {
      $items = this.generateLightboxItem(this.$lightboxTrigger);
    }

    this.$lightboxInner = h('.lightbox__inner', $items);
    const $lightbox = h(
      '.lightbox', { id: baseId },
      [
        $background,
        $closeButton,
      ],
      this.$lightboxInner,
    );

    this.$lightbox = document.body.appendChild($lightbox);

    // Init focus trap
    this.focusTrap = focusTrap(this.$lightbox, {
      escapeDeactivates: false,
      clickOutsideDeactivates: false,
      returnFocusOnDeactivate: true,
    });

    this.focusTrap.activate();

    document.documentElement.classList.add('has-scroll-lock');
    disableBodyScroll(this.$lightbox);

    this.$lightbox.addEventListener('keydown', this.onKeydownBinded);

    // Gallery slider
    if (this.isGallery) {
      const $element = this.$lightbox.querySelector('.lightbox__inner');
      $element.classList.add('lightbox__inner--slider-initialized');

      this.initLoryControls();

      this.lorySlider = lory($element, {
        rewind: true,
        infinite: 0,
        initialIndex: 1,
        slideSpeed: 350,
        ease: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
        classNameFrame: 'lightbox__slider-frame',
        classNameSlideContainer: 'lightbox__slider-slides',
        classNameNextCtrl: 'lightbox__slider-control--next',
        classNamePrevCtrl: 'lightbox__slider-control--prev',
      });

      // Slide to clicked item
      const triggerIndex = getIndex(this.$lightboxTrigger.parentNode.parentNode);
      this.lorySlider.slideTo(triggerIndex);
    }
  }

  initLoryControls() {
    this.$controlPrev = iconButton({
      icon: 'angle-left',
      title: t('Vorheriges Element'),
      large: true,
      white: true,
      classes: ['lightbox__slider-control', 'lightbox__slider-control--prev'],
      ariaHidden: true,
    });

    this.$controlNext = iconButton({
      icon: 'angle-right',
      title: t('Nächstes Element'),
      large: true,
      white: true,
      classes: ['lightbox__slider-control', 'lightbox__slider-control--next'],
      ariaHidden: true,
    });

    this.$lightboxInner.appendChild(this.$controlPrev);
    this.$lightboxInner.appendChild(this.$controlNext);
  }
}

document.querySelectorAll('.js-lightbox').forEach($lightbox => new Lightbox($lightbox));
