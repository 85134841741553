import { MEDIA_QUERIES } from 'javascripts/constants';
import { hover } from 'javascripts/utils/hide-show';
import NavigationMobile from './navigation.mobile';
import NavigationDesktop from './navigation.desktop';

// Event registry
let eventRegistry = [];

document.querySelectorAll('.navigation').forEach(($navigation) => {
  const navigationMobile = new NavigationMobile($navigation);
  const navigationDesktop = new NavigationDesktop($navigation);
  // Init media query
  const mql = window.matchMedia(MEDIA_QUERIES.l);

  // Listener
  const onMediaQueryChange = (mq) => {
    const isDesktop = mq.matches;

    if (isDesktop) {
      navigationMobile.deinit();
      navigationDesktop.init();

      document.querySelectorAll('.navigation__item-link[aria-haspopup="true"]').forEach(($link) => {
        $link.addEventListener('mousedown', e => e.preventDefault());
        eventRegistry.push(hover($link, 200));
      });
    } else {
      // Kill all event handlers on switch
      eventRegistry.forEach(e => e());
      eventRegistry = [];

      navigationDesktop.deinit();
      navigationMobile.init();
    }
  };

  // Listen on media query changes
  mql.addListener(onMediaQueryChange);

  // First run
  onMediaQueryChange(mql);
});
