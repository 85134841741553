import { MEDIA_QUERIES } from 'javascripts/constants';
import h from 'hyperscript';
import Masonry from 'masonry-layout';
import { t } from 'javascripts/utils/withTranslation';

const imageGallery = ($imageGallery) => {
  // Get references
  const $images = $imageGallery.querySelector('.image-gallery__images');
  const $hiddenImages = $imageGallery.querySelectorAll('.image-gallery__image--hidden');

  // Button template
  const $button = h(
    '.image-gallery__button-wrap',
    h('button.button.button.button--primary.button--cta.image-gallery__button', h('span.button__text', '+ …')),
  );

  // Masonry grid
  const masonry = () => {
    const $image = '.image-gallery__image';
    const mqm = window.matchMedia(MEDIA_QUERIES.m).matches;

    if (mqm) {
      const masonryNewslist = new Masonry($images, {
        itemSelector: $image,
        columnWidth: $image,
      });

      $images.addEventListener('lazyloaded', () => {
        masonryNewslist.layout();
      });
    }
  };

  // Show hidden images
  const showImages = () => {
    // Remove hidden class
    $hiddenImages.forEach(($hiddenImage) => {
      $hiddenImage.classList.remove('image-gallery__image--hidden');
    });

    // Hide button
    $button.style.display = 'none';
  };

  if ($images) {
    // Init Masonry
    masonry();

    // Init Masonry again on resize
    window.addEventListener('resize', masonry);
  }

  if ($hiddenImages) {
    // Append button
    $imageGallery.appendChild($button);

    // Get button text
    const $buttonElem = $button.childNodes[0];
    const $buttonText = $buttonElem.childNodes[0];

    // Hidden images count
    const hiddenCountText = t('Restliche Bilder einblenden');
    const hiddenCountNumber = $hiddenImages.length;
    const hiddenCountLabel = `${hiddenCountText} (${hiddenCountNumber})`;

    // Show hidden images count on button
    $buttonElem.setAttribute('aria-label', hiddenCountLabel);
    $buttonElem.setAttribute('title', hiddenCountLabel);
    $buttonText.innerHTML = `+ ${hiddenCountNumber}`;

    // Show hidden images on click
    $button.addEventListener('click', showImages);
  }
};

document.querySelectorAll('.js-image-gallery').forEach($imageGallery => imageGallery($imageGallery));
