import throttle from 'javascripts/utils/throttle';

export default class HeaderSticky {
  constructor($panel) {
    this.$header = document.querySelector('.header');
    this.$panel = $panel;
    this.state = 'not-sticky';

    // Change point
    const scrollOffset = window.scrollY || window.pageYOffset;
    this.change = this.$panel.parentNode.getBoundingClientRect().top + scrollOffset;

    // Binded events
    this.bindedScroll = throttle(this.scroll.bind(this));

    // Add events
    document.addEventListener('scroll', this.bindedScroll, { passive: true });

    // Check for initial scrollposition
    this.scroll();
  }

  deconstructor() {
    // Remove events
    document.removeEventListener('scroll', this.bindedScroll);
  }

  scroll() {
    const scroll = window.scrollY || window.pageYOffset;

    if (scroll >= this.change && this.state === 'not-sticky') {
      this.state = 'sticky';
      window.requestAnimationFrame(() => {
        this.$panel.classList.add('header__panel-inner--visible');
        window.requestAnimationFrame(() => {
          this.$header.classList.add('header--sticky');
          this.$panel.classList.add('header__panel-inner--sticky');
        });
      });
    } else if (scroll < this.change && this.state === 'sticky') {
      this.state = 'not-sticky';
      window.requestAnimationFrame(() => {
        this.$panel.classList.remove('header__panel-inner--sticky');
        this.$panel.classList.remove('header__panel-inner--visible');
        this.$header.classList.remove('header--sticky');
      });
    }
  }
}
