const defaultOutside = ['click', 'focusin'];

function getTarget($trigger) {
  return document.getElementById($trigger.getAttribute('aria-controls'));
}

export function getOutsideHandler($trigger, handler, outside = defaultOutside) {
  const $target = getTarget($trigger);

  return (event) => {
    const insideTarget = event.target === $target
      || event.target === $trigger
      || $trigger.contains(event.target);
    if (!insideTarget) {
      event.preventDefault();
      event.stopPropagation();
      handler($trigger, outside);
    }
  };
}

export function hideShow($trigger, outsideHandler, outside = defaultOutside, focus = true, state = null) { // eslint-disable-line
  const $target = getTarget($trigger);

  if ($target) {
    let expanded = $trigger.getAttribute('aria-expanded') === 'true';
    if (state !== null) expanded = !state;

    $trigger.setAttribute('aria-expanded', expanded ? 'false' : 'true');
    $target.setAttribute('aria-hidden', expanded ? 'true' : 'false');

    if (focus) {
      $target.setAttribute('tabindex', expanded ? '-1' : '0');
    }

    if (!expanded) {
      if (focus) {
        $target.focus();
      }

      if (outsideHandler) {
        outside.forEach(o => document.addEventListener(o, outsideHandler));
      }
    } else if (outsideHandler) {
      outside.forEach(o => document.removeEventListener(o, outsideHandler));
    }
  }
}

export function click($trigger, withOutsideHandler = true, outside = defaultOutside) {
  // Get outside event handler
  const outsideHandler = withOutsideHandler ? getOutsideHandler($trigger, hideShow, outside) : null;
  // Generate event handler
  const handler = (event) => {
    event.preventDefault();
    hideShow($trigger, outsideHandler, outside, true);
  };

  // Attach event handler
  $trigger.addEventListener('click', handler);

  // Kill function
  return () => $trigger.removeEventListener('click', handler);
}

export function hover($trigger, delay = 0) {
  // Get target
  const $target = getTarget($trigger);

  // Delay handler
  let delayHandler = null;

  // Still inside?
  const stillInside = (event) => {
    if (event.relatedTarget) {
      const check = event.relatedTarget === $target
        || event.relatedTarget === $trigger
        || $target.contains(event.relatedTarget)
        || $trigger.contains(event.relatedTarget);

      if (check) return true;
    }

    return false;
  };

  // Generate event handler
  const delayedHandlerIn = (event) => {
    event.preventDefault();

    if (stillInside(event)) {
      return;
    }

    // Deactivate delay when event is focusin
    const delayIn = (event.type === 'focusin') ? 0 : delay;

    delayHandler = setTimeout(() => {
      hideShow($trigger, false, [], false, true);
    }, delayIn);
  };

  const delayedHandlerOut = (event) => {
    event.preventDefault();

    if (stillInside(event)) {
      return;
    }

    clearTimeout(delayHandler);
    hideShow($trigger, false, [], false, false);
  };

  // Attach event handler to trigger
  ['mouseenter', 'focusin'].forEach((type) => {
    $trigger.addEventListener(type, delayedHandlerIn);
  });

  ['mouseleave', 'focusout'].forEach((type) => {
    $trigger.addEventListener(type, delayedHandlerOut);
  });

  // Attach event handler to target
  ['mouseleave', 'focusout'].forEach((type) => {
    $target.addEventListener(type, delayedHandlerOut);
  });

  // Kill function
  return () => {
    ['mouseenter', 'mouseleave', 'focusin', 'focusout'].forEach((type) => {
      $trigger.removeEventListener(type, delayedHandlerIn);
      $trigger.removeEventListener(type, delayedHandlerOut);
      $target.removeEventListener(type, delayedHandlerOut);
    });
  };
}
